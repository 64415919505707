import {Container, Row, Col, Image} from 'react-bootstrap';

const about = {
    id:'about',
    title:'What Do We Do?',
    copy:<><p><b>Structural Melbourne</b> provides <b>structural and civil engineering services</b>, specialising in <b>residential buildings</b>. Our goal is to produce cost effective <b>documentation</b> which leads to ease of <b>construction</b>. We can provide all of your <b>engineering</b> requirements from <b>soil report</b>, to final<b> certification</b> – all at <b>competitive rates</b>.</p> <p><b>Structural Melbourne</b> gets you your <b>structural design</b> quickly and affordably. We pride ourselves on developing and implementing structural <b>design solutions</b> that are innovative, practical and <b>economic</b>, that achieve our clients objectives, are <b>environmentally sustainable</b> and that comply with all current and relevant <b>Australian Standards</b>.</p> <p><b>Structural Melbourne</b> caters for a wide variety of <b>engineering consulting services</b>, including <b>residential</b>, <b>commercial</b> and <b>industrial projects</b>.  All projects utilise the latest computer software packages for <b>analysis, design and drafting</b>, all designs incorporate a large range of <b>materials</b> including; <b>timber, structural steel, masonry, block work and reinforced concrete</b>. <b>Structural Melbourne</b> offers highly <b>competitive</b> quotations. We provide the quickest turnarounds in <b>Melbourne</b>.</p> <p>We look forward to meeting and working with you on your next project – Welcome to <b>Structural Melbourne!</b></p></>,
    image:{
        url: 'img02.webp',
        alt: 'We Are Structural Melbourne'
    }
}

const About =()=> (
    <Container fluid className='bg-sanjuan' id={about.id}>
        <Container className='pt-5'>
            <Row className='pt-5 flex-row animate-opacity animate-bottom'>
                <h2 className='text-center pb-5'>{about.title}</h2>
                <Col lg={6} className='text-center pb-5'>
                    <Image src={about.image.url} alt={about.image.alt} fluid rounded />
                </Col>
                <Col lg={6} className='pb-5 justify-content-center align-self-center'>
                    {about.copy}
                </Col>
            </Row>
        </Container>
    </Container>
)

export default About;