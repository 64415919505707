import Menu from './menu.js';
import Home from './home.js';
import About from './about.js';
import Services from './services.js';
import Contact from './contact.js';
import Footer from './footer.js';

const App =()=> (
  <div className='App'>
    <header>        
      <Menu />
    </header>
    <main>
      <Home />
      <About />
      <Services />
      <Contact />
      <Footer />
    </main>
  </div>
)

export default App;
