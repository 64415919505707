import {Container, Row, Col, Image} from 'react-bootstrap';

const services = {
  id:'services',
  colClass: 'my-5 gx-5 text-left justify-content-center align-self-top animate-opacity animate-bottom',
  item1:{
    src:'img03.webp',
    alt: 'structural melbourne new homes',
    class: 'mb-4'
  },
  item2:{
    src:'img04.webp',
    alt: 'structural melbourne townhouses',
    class: 'mb-4'
  },
  item3:{
    src:'img05.webp',
    alt: 'structural melbourne site inspections',
    class: 'mb-4'
  }
};

const Services = () => (
    <Container fluid className='bg-light' id={services.id}>
        <Container className='pt-5'>
          <Row className='pt-5 flex-row animate-opacity animate-bottom'>
            <h2 className='text-center pb-5'> Our Services </h2>
            <Col lg={4} className={services.colClass}>
              <Image src={services.item1.src} alt={services.item1.alt} className={services.item1.class} thumbnail />
              <ul>
              <li><h3> New homes </h3></li>
              <li><h3> Garages Alterations </h3></li>
              <li><h3> and Extensions </h3></li>
              </ul>
            </Col>
            <Col lg={4} className={services.colClass}>
              <Image src={services.item2.src} alt={services.item2.alt} className={services.item2.class} thumbnail />
              <ul>
              <li><h3> Townhouses </h3></li>
              <li><h3> Footing Design </h3></li>
              <li><h3> Basements </h3></li>
              </ul>
            </Col>
            <Col lg={4}className={services.colClass}>
              <Image src={services.item3.src} alt={services.item3.alt} className={services.item3.class} thumbnail />
              <ul>
              <li><h3> Retaining walls </h3></li>
              <li><h3> Signage and lighting towers </h3></li>
              <li><h3> Site Inspections / Reports for Distressed Buildings </h3></li>
              </ul>
            </Col>
            </Row>
          </Container>
      </Container>
);
export default Services;