import {Container, Row, Col, Image} from 'react-bootstrap';
import { H, Section } from 'react-headings';

const home = {
  alt: 'We Are Structural Melbourne',
  url: 'img01.webp',
  copy:<><p><b>Structural Melbourne</b> provides <b>structural and civil engineering services</b>, specialising in <b>residential buildings</b>. Our goal is to produce cost effective <b>documentation</b> which leads to ease of <b>construction</b>.</p><h2>Call Us Today: <a href='tel:+61458876290' className='nightrider'>0458 876 290</a></h2></>
}

const Home =()=> (
    <Container fluid className='bg-light'>
        <Container className='pt-5'>
          <Row className='pt-5 flex-row-reverse animate-opacity animate-bottom'>
            <Col lg={6} className='py-5 text-center justify-content-center align-self-center'>
              <Image src={home.url} alt={home.alt} fluid rounded />
            </Col>
            <Col lg={6} className='pb-5 text-center justify-content-center align-self-center'>
              <Section component={<H> We Are Structural Melbourne</H>}></Section>
                {home.copy}
            </Col>
          </Row>
        </Container>
    </Container>
)

export default Home;