import {Container, Row, Col, Image} from 'react-bootstrap';
import Mailer from './mailer.js';

const contact = {
  id:'contact',
  title:'Get in touch with us',
  colClass: 'animate-opacity animate-bottom my-3',
  address:<><b>Address:</b> 65 Newlands Road, Coburg North VIC 3058</>,
  phone:<><b>Phone:</b> <a href='tel:+61458876290'  className='text-white'>0458 876 290</a></>,
  email:<><b>Email:</b> <a href='mailto:info@structuralmelbourne.com.au'  className='text-white'>info@structuralmelbourne.com.au</a></>,
  likedin:{
  	src: 'linkedin.svg',
  	alt: 'structural melbourne linkedin',
  	className: 'px-1 social',
  	url: 'https://structuralmelbourne.com.au'
  }
}

const Contact =()=> (
    <Container fluid className='bg-sanjuan' id={contact.id}>
        <Container className='pt-5'>
            <Row className='pt-5 flex-row animate-opacity animate-bottom'>
              <h2 className='text-center pb-5'>{contact.title}</h2>
              <Col sm={6} className={contact.colClass}>
	              <Mailer />
              </Col>
              <Col sm={6} className={contact.colClass}>
              <p>
	              {contact.address}<br/>
	              {contact.phone}<br/>
	              {contact.email}
              </p>
              <a href={contact.likedin.url} target='_blank' rel='noopener noreferrer'>
	              <Image src={contact.likedin.src} alt={contact.likedin.alt} className={contact.likedin.className} />
              </a>
              </Col>
            </Row>
        </Container>
    </Container>
)

export default Contact;