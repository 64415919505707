import {Container, Navbar, Nav} from 'react-bootstrap';

const brand = {
  alt: 'structural melbourne',
  url: 'logo-horizontal.svg',
  width: 175,
  href: 'https://www.structuralmelbourne.com.au/'
}

const menu = {
  link1:{
    copy:'What Do We Do?',
    url: '#about'
  },
  link2:{
    copy:'Services',
    url: '#services'
  },
  link3:{
    copy:'Contact Us',
    url: '#contact'
  },
  link4:{
    copy:'Call Us Today: 0458 876 290',
    url: 'tel:+61458876290'
  }
}

const Menu =()=> (
  <Navbar fixed='top' collapseOnSelect expand='lg'>
      <Container>
          <Navbar.Brand href={brand.href}>
            <img src={brand.url} alt={brand.alt} className='d-inline-block align-top' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link href={menu.link1.url}>{menu.link1.copy}</Nav.Link>
            <Nav.Link href={menu.link2.url}>{menu.link2.copy}</Nav.Link>
            <Nav.Link href={menu.link3.url}>{menu.link3.copy}</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href={menu.link4.url}>{menu.link4.copy}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
  </Navbar>
)

export default Menu;