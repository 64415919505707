import React, { useState } from 'react';
import {Container, Button, Form} from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha'

const Mailer =()=> {

    const onChange =()=> (
    document.getElementById('send-button').disabled = false
    )

    const [show, setShow] = useState(undefined);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm
        (
            'service_9vlv7df', 
            'template_b9486gq', 
            e.target, 
            'W19rw2dUHNtpDrqzL'
        )
        .then(()=>
            {
                setShow({type: 'success'})
                e.target.reset()
            })
        .catch(error=>
            setShow({type:  'error', error})
        )
        
    }

    return (
        <Container>
            <h2 className='mt-3'>Contact Us</h2>
            <>
                {show?.type === 'success' && <div className='mt-2 rounded-3 p-2 text-center bg-success text-white'>Your message has been sent successfully</div>}
                {show?.type === 'error' && <div className='mt-2 rounded-3 p-2 text-center bg-danger text-white'>Message sending failed. Please try again.</div>}
            </>
            <Form onSubmit={sendEmail}>

                <Form.Group className='mb-3' id='formBasicEmail'>

                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Name" name='name' id='formName' required/>
                    
                    <Form.Label>Email</Form.Label>
                    <Form.Control type='email' placeholder='Enter email' name='user_email' id='formUser_email' required/>

                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} type='message' placeholder='message' name='message' id='formMessage' required />

                </Form.Group>

                <ReCAPTCHA sitekey={process.env.REACT_APP_SITEKEY} onChange={onChange} className='mb-3'/>

                <Button id='send-button' variant='primary' type='submit' value='send' className='bg-panache' disabled>
                send
                </Button>
            </Form>
        </Container>
    );
};


export default Mailer;