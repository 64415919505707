import {Container, Row} from 'react-bootstrap';

const Footer = () => (
    <Container fluid className='bg-light'>
        <Container>
            <Row className='py-3 text-left'>
                <small>&#169; {(new Date().getFullYear())} Melbourne Structural Pty Ltd ABN 47 644 792 852 - <a href='privacypolicy.pdf' >privacy policy</a> - <a href='https://carlosesierra.com.au/' target='blank_' rel='noopener'>web design</a> by carlosesierra.com.au.</small>
            </Row>
        </Container>
    </Container>
)

export default Footer;